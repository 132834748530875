<template>
  <v-container fluid>
    <Editor :initial="dataItem" :pageMode="pageMode" :pageTitle="pageTitle" @data="save" />
  </v-container>
</template>
<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      dataItem: {},
      pageMode: 'create',
      pageTitle: 'Create Channel'
    };
  },
  methods: {
    save(data) {
      //logic comes here
      const url = "/channels";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/channels");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>